<template>
  <v-container>
    <v-row>
      <v-col class="mt-4" cols="6" align="right">
        <v-progress-circular
          :width="3"
          color="green"
          :indeterminate="load"
          v-if="showLoad"
          class="mt-5"
        ></v-progress-circular>
      </v-col>
      <v-col v-if="false" cols="6" align="right">
        <v-btn-toggle v-model="timeStatus" color="primary">
          <v-btn text small style="height: 50px" value="week">Weekly</v-btn>
          <v-btn text small style="height: 50px" value="month">Monthly</v-btn>

          <v-btn text small style="height: 50px" value="quarterly">
            Quarterly
          </v-btn>
          <v-btn text small style="height: 50px" value="year">1 Year</v-btn>
        </v-btn-toggle>
      </v-col>
    </v-row>
    
    <v-row>
      <v-col cols="8">
        <v-app style="background-color: #eeeeee">
          <v-container>
            <v-row>
              <v-col cols="12">
                <base-material-card color="primary">
                  <template v-slot:heading>
                    <div class="text-h3 font-weight-light">Vehicles</div>
                  </template>
                  <v-card
                    v-if="userDetails"
                    elevation="0"
                    class="pa-2"
                  >
                    <template>
                      <v-data-table
                        :headers="headersEdu"
                        :items="eduDetails"
                        :items-per-page="5"
                        class="elevation-1"
                      >
                        <template v-slot:top>
                          <v-toolbar flat>
                            <v-spacer></v-spacer>
                            <v-dialog v-model="dialogEdu" max-width="600px">
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                  color="primary"
                                  dark
                                  class="mb-2"
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                  New Item
                                </v-btn>
                              </template>
                              <v-card>
                                <v-card-title>
                                  <span class="text-h5">
                                    {{ formTitleEdu }}
                                  </span>
                                </v-card-title>

                                <v-card-text>
                                  <v-container>
                                    <v-row>
                                      <v-col cols="12" sm="6" md="6">
                                        <v-text-field
                                          v-model="editedItemEdu.make"
                                          label="Make"
                                        ></v-text-field>
                                      </v-col>
                                      <v-col cols="12" sm="6" md="6">
                                        <v-text-field
                                          v-model="editedItemEdu.registration"
                                          label="Registration"
                                        ></v-text-field>
                                      </v-col>
                                      <v-col cols="12" sm="6" md="6">
                                        <v-text-field
                                          v-model="editedItemEdu.type"
                                          label="Type"
                                        ></v-text-field>
                                      </v-col>
                                      <v-col cols="12" sm="6" md="6">
                                        <v-text-field
                                          v-model="editedItemEdu.maxPassengers"
                                          label="Max Passengers"
                                        ></v-text-field>
                                      </v-col>
                                    </v-row>
                                  </v-container>
                                </v-card-text>

                                <v-card-actions>
                                  <v-spacer></v-spacer>
                                  <v-btn
                                    color="blue darken-1"
                                    text
                                    @click="closeEdu"
                                  >
                                    Cancel
                                  </v-btn>
                                  <v-btn
                                    color="blue darken-1"
                                    text
                                    @click="saveEdu()"
                                  >
                                    Save
                                  </v-btn>
                                </v-card-actions>
                              </v-card>
                            </v-dialog>
                            <v-dialog
                              v-model="dialogDeleteEdu"
                              max-width="600px"
                            >
                              <v-card>
                                <v-card-title class="text-h5">
                                  Are you sure you want to delete?
                                </v-card-title>
                                <v-card-actions>
                                  <v-spacer></v-spacer>
                                  <v-btn
                                    color="blue darken-1"
                                    text
                                    @click="closeDeleteEdu"
                                  >
                                    Cancel
                                  </v-btn>
                                  <v-btn
                                    color="blue darken-1"
                                    text
                                    @click="
                                      vehicleDeleteConfirm(editedItemEdu.id)
                                      "
                                  >
                                    OK
                                  </v-btn>
                                  <v-spacer></v-spacer>
                                </v-card-actions>
                              </v-card>
                            </v-dialog>
                          </v-toolbar>
                        </template>
                        <template v-slot:[`item.image_edu`]="{ item }">
                          <v-avatar size="50px">
                            <img
                              v-if="item.image_edu == null"
                              src="@/assets/study.png"
                              alt="Education institue image"
                            />
                            <img
                              v-if="item.image_edu"
                              :src="item.image_edu"
                              alt="Education institue image"
                            />
                          </v-avatar>
                        </template>
                        <template v-slot:[`item.actions`]="{ item }">
                          <v-icon small class="mr-2" @click="editItemEdu(item)">
                            mdi-pencil
                          </v-icon>
                          <v-icon small @click="deleteItemEdu(item)">
                            mdi-delete
                          </v-icon>
                        </template>
                      </v-data-table>
                    </template>
                  </v-card>
                </base-material-card>
              </v-col>

              <v-col cols="12">
                <base-material-card color="primary">
                  <template v-slot:heading>
                    <div class="text-h3 font-weight-light">Recent Bookings</div>
                  </template>
                  <v-card v-if="userDetails" elevation="0" class="pa-2 mt-n15">
                    <template>
                      <v-data-table
                        :headers="headers"
                        :items="bookings"
                        :items-per-page="5"
                        sort-by="calories"
                        class="elevation-1"
                      >
                        <template v-slot:[`item.pickupAdr`]="{ item }">
                          <v-tooltip top>
                            <template v-slot:activator="{ on }">
                              <div v-on="on">
                                {{ getShortAddress(item.pickupAdr) }}
                              </div>
                            </template>
                            <span>{{ item.pickupAdr }}</span>
                          </v-tooltip>
                        </template>
                        <template v-slot:[`item.dropoffAdr`]="{ item }">
                          <v-tooltip top>
                            <template v-slot:activator="{ on }">
                              <div v-on="on">
                                {{ getShortAddress(item.dropoffAdr) }}
                              </div>
                            </template>
                            <span>{{ item.dropoffAdr }}</span>
                          </v-tooltip>
                        </template>
                        <template v-slot:top>
                          <v-toolbar flat>
                            <v-spacer></v-spacer>
                            <v-dialog v-model="dialog" max-width="500px">
                              <v-card>
                                <v-card-title>
                                  <span class="text-h5">{{ formTitle }}</span>
                                </v-card-title>

                                <v-card-text>
                                  <v-container>
                                    <v-row>
                                      <v-col cols="12" sm="6" md="6">
                                        <v-text-field
                                          v-model="editedItem.job"
                                          label="Job Title"
                                        ></v-text-field>
                                      </v-col>
                                      <v-col cols="12" sm="6" md="6">
                                        <v-text-field
                                          v-model="editedItem.location"
                                          label="Location"
                                        ></v-text-field>
                                      </v-col>
                                      <v-col cols="12" sm="12" md="12">
                                        <v-text-field
                                          v-model="editedItem.company"
                                          label="Company"
                                        ></v-text-field>
                                      </v-col>

                                      <v-col cols="12" sm="6" md="6">
                                        <v-dialog
                                          ref="menuexp"
                                          v-model="menuexp"
                                          :close-on-content-click="false"
                                          transition="scale-transition"
                                          offset-y
                                          persistent
                                          width="290px"
                                        >
                                          <template
                                            v-slot:activator="{ on, attrs }"
                                          >
                                            <v-text-field
                                              v-model="editedItem.start_date"
                                              label="Start Date"
                                              readonly
                                              v-bind="attrs"
                                              v-on="on"
                                            ></v-text-field>
                                          </template>
                                          <v-date-picker
                                            v-model="editedItem.start_date"
                                            type="month"
                                            :active-picker.sync="activePickerexp
                                              "
                                            :max="new Date(
                                              Date.now() -
                                              new Date().getTimezoneOffset() *
                                              60000
                                            )
                                              .toISOString()
                                              .substr(0, 10)
                                              "
                                            min="1950-01-01"
                                          >
                                            <v-spacer></v-spacer>
                                            <v-btn
                                              text
                                              color="primary"
                                              @click="menuexp = false"
                                            >
                                              Cancel
                                            </v-btn>
                                            <v-btn
                                              text
                                              color="primary"
                                              @click="
                                                $refs.menuexp.save(
                                                  editedItem.start_date
                                                )
                                                "
                                            >
                                              OK
                                            </v-btn>
                                          </v-date-picker>
                                        </v-dialog>
                                      </v-col>
                                      <v-col
                                        cols="12"
                                        sm="6"
                                        md="6"
                                        v-if="editedItem.current_job == true"
                                      ></v-col>
                                      <v-col
                                        cols="12"
                                        sm="6"
                                        md="6"
                                        v-if="editedItem.current_job == false"
                                      >
                                        <v-dialog
                                          ref="menuexpend"
                                          v-model="menuexpend"
                                          :close-on-content-click="false"
                                          transition="scale-transition"
                                          persistent
                                          offset-y
                                          width="290px"
                                        >
                                          <template
                                            v-slot:activator="{ on, attrs }"
                                          >
                                            <v-text-field
                                              v-model="editedItem.end_date"
                                              label="End Date"
                                              readonly
                                              v-bind="attrs"
                                              v-on="on"
                                            ></v-text-field>
                                          </template>
                                          <v-date-picker
                                            v-model="editedItem.end_date"
                                            type="month"
                                            :active-picker.sync="activePickerexpend
                                              "
                                            :max="new Date(
                                              Date.now() -
                                              new Date().getTimezoneOffset() *
                                              60000
                                            )
                                              .toISOString()
                                              .substr(0, 10)
                                              "
                                            min="1950-01-01"
                                          >
                                            <v-spacer></v-spacer>
                                            <v-btn
                                              text
                                              color="primary"
                                              @click="menuexpend = false"
                                            >
                                              Cancel
                                            </v-btn>
                                            <v-btn
                                              text
                                              color="primary"
                                              @click="
                                                $refs.menuexpend.save(
                                                  editedItem.end_date
                                                )
                                                "
                                            >
                                              OK
                                            </v-btn>
                                          </v-date-picker>
                                        </v-dialog>
                                      </v-col>
                                      <v-col cols="12" sm="6" md="6">
                                        <v-checkbox
                                          v-model="editedItem.current_job"
                                          label="Currently Working"
                                        ></v-checkbox>
                                      </v-col>
                                      <v-col cols="12" sm="6" md="6">
                                        <v-file-input
                                          v-model="editedItem.image_exp"
                                          :rules="[
                                            (value) =>
                                              !value ||
                                              value.size < 2000000 ||
                                              'Image size should be less than 2 MB!',
                                          ]"
                                          accept="image/png, image/jpeg, image/bmp"
                                          placeholder="Pick an Image"
                                          prepend-icon="mdi-camera"
                                          label="Institute Image"
                                        />
                                      </v-col>
                                      <v-col cols="12" sm="12" md="12">
                                        <v-textarea
                                          v-model="editedItem.description"
                                          clearable
                                          clear-icon="mdi-close-circle"
                                          label="Description"
                                          :value="editedItem.description"
                                        ></v-textarea>
                                      </v-col>
                                    </v-row>
                                  </v-container>
                                </v-card-text>

                                <v-card-actions>
                                  <v-spacer></v-spacer>
                                  <v-btn
                                    color="blue darken-1"
                                    text
                                    @click="close"
                                  >
                                    Cancel
                                  </v-btn>
                                  <v-btn
                                    color="blue darken-1"
                                    text
                                    @click="save"
                                  >
                                    Save
                                  </v-btn>
                                </v-card-actions>
                              </v-card>
                            </v-dialog>
                            <template>
                              <v-dialog
                                v-model="dialogDelete"
                                max-width="500px"
                              >
                                <v-card>
                                  <v-card-title class="text-h5">
                                    Are you sure you want to delete?
                                  </v-card-title>
                                  <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn
                                      color="blue darken-1"
                                      text
                                      @click="closeDelete"
                                    >
                                      Cancel
                                    </v-btn>
                                    <v-btn
                                      color="blue darken-1"
                                      text
                                      @click="deleteItemConfirm(editedItem.id)"
                                    >
                                      OK
                                    </v-btn>
                                    <v-spacer></v-spacer>
                                  </v-card-actions>
                                </v-card>
                              </v-dialog>
                            </template>
                          </v-toolbar>
                        </template>
                        <template v-slot:[`item.image_exp`]="{ item }">
                          <v-avatar size="50px">
                            <img
                              v-if="item.image_exp == null"
                              src="@/assets/job.png"
                              alt="image"
                            />
                            <img
                              v-if="item.image_exp"
                              :src="item.image_exp"
                              alt="image"
                            />
                          </v-avatar>
                        </template>
                        <template v-slot:[`item.current_job`]="{ item }">
                          <p>
                            {{ item.current_job ? "Yes" : "No" }}
                          </p>
                        </template>
                        <template v-slot:[`item.end_date`]="{ item }">
                          <p class="mt-3">
                            {{ item.current_job ? "" : item.end_date }}
                          </p>
                        </template>
                        <template v-slot:[`item.actions`]="{ item }">
                          <v-icon small class="mr-2" @click="editItem(item)">
                            mdi-pencil
                          </v-icon>
                          <v-icon small @click="deleteItem(item)">
                            mdi-delete
                          </v-icon>
                        </template>
                      </v-data-table>
                    </template>
                  </v-card>
                </base-material-card>
              </v-col>

            </v-row>
          </v-container>
        </v-app>
      </v-col>
      
      <v-col cols="4">
        <v-app style="background-color: #eeeeee">
          <v-container>
            <v-row>
              <v-col cols="12">
                <base-material-card color="primary">
                  <template v-slot:heading>
                    <div class="text-h3 font-weight-light">General Details</div>
                  </template>
                  <v-card v-if="userDetails" elevation="0" class="pa-2">
                    <template v-if="customDetails">
                      <v-form>
                        <v-col md="6" style="margin-top: -17px">
                          <v-avatar
                            size="130px"
                            style="margin-left: 60px; margin-top: -25px"
                            v-if="showFileInput == false && userDetailsEdit"
                            :key="customDetails.profile_photo"
                          >
                            <img
                              v-if="customDetails.profile_photo == null"
                              src="@/assets/user.png"
                              style="margin-left: 35px"
                            />
                            <img
                              v-else
                              :src="customDetails.profile_photo"
                              alt="User Image"
                            />
                          </v-avatar>
                          <v-btn
                            color="primary"
                            x-small
                            fab
                            dark
                            style="margin-top: -250px; margin-left: 160px"
                            v-if="showFileInput == false && userDetailsEdit"
                            @click="openFileInput"
                          >
                            <v-icon>mdi-pencil</v-icon>
                          </v-btn>
                          <input
                            type="file"
                            ref="avatarFileInput"
                            style="display: none"
                            accept="image/*"
                            @change="onAvatarFileChange"
                          />
                        </v-col>
                        <v-row
                          class="no-gutters py-2"
                          style="margin-bottom: 25px"
                          v-if="userDetailsEdit"
                        >
                          <v-col md="6">
                            <h5>FirstName :</h5>
                            <v-text-field
                              v-model="customDetails.firstname"
                              class="purple-input"
                              style="margin-top: -14px; margin-right: 30px"
                            />
                          </v-col>
                          <v-col md="6">
                            <h5>LastName :</h5>
                            <v-text-field
                              v-model="customDetails.lastname"
                              class="purple-input"
                              style="margin-top: -14px; margin-right: 30px"
                            />
                          </v-col>
                          <v-col md="12">
                            <h5>Email :</h5>
                            <v-text-field
                              v-model="customDetails.email"
                              class="purple-input"
                              style="margin-top: -14px; margin-right: 30px"
                            />
                          </v-col>
                          <v-col md="12">
                            <h5>Phone :</h5>
                            <v-text-field
                              v-model="customDetails.phone"
                              class="purple-input"
                              style="margin-top: -14px; margin-right: 30px"
                              readonly
                            />
                          </v-col>
                          <v-col md="12">
                            <h5>License ID :</h5>
                            <v-text-field
                              v-model="customDetails.licenseNumber"
                              class="purple-input"
                              style="margin-top: -14px; margin-right: 30px"
                            />
                          </v-col>
                          <v-col md="8">
                            <v-file-input
                              v-model="customDetails.profile_photo"
                              :rules="[
                                (value) =>
                                  !value ||
                                  value.size < 2000000 ||
                                  'Image size should be less than 2 MB!',
                              ]"
                              accept="image/png, image/jpeg, image/bmp"
                              placeholder="Pick an Image"
                              prepend-icon="mdi-camera"
                              label="User Image"
                              v-if="showFileInput"
                            />
                          </v-col>
                        </v-row>

                        
                        <template v-if="userDetailsEdit != true">
                          <v-col md="6">
                            <v-avatar
                              size="130px"
                              style="margin-top: -60px; margin-left: 60px"
                            >
                              <img
                                v-if="customDetails.profile_photo == null"
                                src="@/assets/user.png"
                                style="margin-left: 65px"
                              />
                              <img
                                :src="customDetails.profile_photo"
                                alt="useriamge"
                              />
                            </v-avatar>
                          </v-col>
                          <!-- <v-col>
                            <div style="margin-top: -10px; margin-left: 80px">
                              <span
                                ><u>
                                  Balance:
                                  <strong
                                    >£
                                    {{
                                      customDetails.wallet.toFixed(2)
                                    }}</strong
                                  ></u
                                ></span
                              >
                            </div>
                          </v-col> -->
                          <v-row class="no-gutters py-2">
                            <v-col md="6">
                              <h5>FirstName :</h5>
                              <v-text-field
                                v-model="customDetails.firstname"
                                class="purple-input"
                                style="margin-top: -14px; margin-right: 30px"
                                readonly
                              />
                            </v-col>
                            <v-col md="6">
                              <h5>LastName :</h5>
                              <v-text-field
                                v-model="customDetails.lastname"
                                class="purple-input"
                                style="margin-top: -14px; margin-right: 30px"
                                readonly
                              />
                            </v-col>
                            <v-col md="12">
                              <h5>Phone :</h5>
                              <v-text-field
                                v-model="customDetails.phone"
                                class="purple-input"
                                style="margin-top: -14px; margin-right: 30px"
                                readonly
                              />
                            </v-col>
                            <v-col md="12">
                              <h5>Email :</h5>
                              <v-text-field
                                v-model="customDetails.email"
                                class="purple-input"
                                style="margin-top: -14px; margin-right: 30px"
                                readonly
                              />
                            </v-col>
                            <v-col md="6">
                              <h5>License ID :</h5>
                              <v-text-field
                                v-model="customDetails.licenseNumber"
                                class="purple-input"
                                style="margin-top: -14px; margin-right: 30px"
                                readonly
                              />
                            </v-col>
                            <v-col md="6" v-if="!customDetails.company">
                              <h5>Years of Experience :</h5>
                              <v-text-field
                                v-model="customDetails.yearsOfExperience"
                                class="purple-input"
                                style="margin-top: -14px; margin-right: 30px"
                                readonly
                              />
                            </v-col>
                            <v-col md="6">
                              <h5>Type :</h5>
                              <v-text-field
                                v-model="customDetails.type"
                                class="purple-input"
                                style="margin-top: -14px; margin-right: 30px"
                                readonly
                              />
                            </v-col>
                            <v-col md="6">
                              <h5>Status :</h5>
                              <v-text-field
                                v-model="customDetails.status"
                                class="purple-input"
                                style="margin-top: -14px; margin-right: 30px"
                                readonly
                              />
                            </v-col>
                            <!-- <v-col md="6">
                              <v-checkbox
                              v-model="customDetails.is_featured"
                              label="Featured"
                              readonly
                            />
                            </v-col>
                            <v-col md="12">
                              <h5>Bio</h5>
                              <v-textarea
                                readonly
                                rows="8"
                                :value="customDetails.bio"
                              ></v-textarea>
                            </v-col> -->
                          </v-row>
                        </template>
                      </v-form>
                    </template>
                    <v-btn
                      color="success"
                      class="mr-0"
                      @click="enableEditUserDetail()"
                      v-if="userDetailsEdit != true"
                    >
                      Edit
                    </v-btn>
                    <v-btn
                      color="success"
                      class="mr-0"
                      @click="updateAction()"
                      v-if="userDetailsEdit"
                      :loading="generealDetailsSaveBtnLd"
                    >
                      Save
                    </v-btn>
                  </v-card>
                </base-material-card>
              </v-col>
            </v-row>
          </v-container>
        </v-app>
      </v-col>
    </v-row>
    

    
    <v-row>
      <v-col cols="4">
        <base-material-card color="primary">
          <template v-slot:heading>
            <div class="text-h3 font-weight-light" style="display: flex; align-items: center;">
              <span style="margin-right: 10px;">{{(!customDetails.company) ? 'Business Details' : 'Address'}}</span>
              <v-spacer />
              <v-icon
                @click="driverBusinessDialog = true"
              >mdi-pencil</v-icon>
            </div>
           
            
          </template>
          <v-card v-if="userDetails" elevation="0" class="mt-n6">
            <template>
              <v-data-iterator
                :items="businessDetailTable"
                :items-per-page="5"
                hide-default-footer
              >
                <template v-slot:default="props">
                  <v-row>
                    <v-col
                      v-for="item in props.items"
                      :key="item.name"
                      cols="12"
                    >
                      <v-card elevation="0">
                        <v-list dense>
                          <v-list-item v-if="!customDetails.company">
                            <v-list-item-content>
                              Business Name:
                            </v-list-item-content>
                            <v-list-item-content class="align-end">
                              {{ item.businessName }}
                            </v-list-item-content>
                          </v-list-item>

                          <v-list-item v-if="!customDetails.company">
                            <v-list-item-content>
                              Business Address:
                            </v-list-item-content>
                            <v-list-item-content class="align-end">
                              {{ item.businessAddress }}
                            </v-list-item-content>
                          </v-list-item>

                          <v-list-item>
                            <v-list-item-content> {{(!customDetails.company) ? 'City' : 'Address'}}: </v-list-item-content>
                            <v-list-item-content class="align-end">
                              {{ item.city }}
                            </v-list-item-content>
                          </v-list-item>

                          <v-list-item>
                            <v-list-item-content>
                              Post Code:
                            </v-list-item-content>
                            <v-list-item-content class="align-end">
                              {{ item.postCode }}
                            </v-list-item-content>
                          </v-list-item>

                          <v-list-item v-if="!customDetails.company">
                            <v-list-item-content>
                              Business Link:
                            </v-list-item-content>
                            <v-list-item-content class="align-end">
                              {{ item.link }}
                            </v-list-item-content>
                          </v-list-item>
                        </v-list>
                      </v-card>
                    </v-col>
                  </v-row>
                </template>
              </v-data-iterator>
            </template>
          </v-card>
        </base-material-card>
      </v-col>
      
      <v-col cols="4">
        <base-material-card color="primary">
          <template v-slot:heading>
            <div class="text-h3 font-weight-light" style="display: flex; align-items: center;">
              <span style="margin-right: 10px;">License Details</span>
              <v-spacer />
              <v-icon
                @click="driverLicenseDialog = true"
              >mdi-pencil</v-icon>
            </div>
          </template>
          <v-card v-if="userDetails" elevation="0" class="mt-n6">
            <v-data-iterator
              :items="licenceDetailTable"
              :items-per-page="5"
              hide-default-footer
            >
              <template v-slot:default="props" style="mt-2">
                <v-row>
                  <v-col v-for="item in props.items" :key="item.name" cols="12">
                    <v-card elevation="0">
                      <v-list dense>
                        <v-list-item>
                          <v-list-item-content>
                            license Number:
                          </v-list-item-content>
                          <v-list-item-content class="align-end">
                            {{ item.licenseNumber }}
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item>
                          <v-list-item-content>
                            Verification:
                          </v-list-item-content>
                          <v-list-item-content class="align-end">
                            {{ item.isVerified }}
                          </v-list-item-content>
                        </v-list-item>

                        <v-list-item>
                          <v-list-item-content>
                            Expiry Date:
                          </v-list-item-content>
                          <v-list-item-content class="align-end">
                            {{ getReadableDate(item.expiryDate) }}
                          </v-list-item-content>
                        </v-list-item>

                        <v-list-item>
                          <v-list-item-content> Image: </v-list-item-content>
                          <v-list-item-content class="align-end">
                            <template>
                              <div class="text-center">
                                <v-dialog v-model="dialogL" width="500">
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                      style="margin-left: -80px"
                                      outlined
                                      small
                                      fab
                                      color="green"
                                      v-bind="attrs"
                                      v-on="on"
                                    >
                                      <v-icon>mdi-attachment</v-icon>
                                    </v-btn>
                                  </template>

                                  <v-card class="d-flex flex-column">
                                    <v-card-title class="text-h5 grey lighten-2">
                                      Image on license
                                    </v-card-title>

                                    <v-card-text class="d-flex justify-center align-center">
                                      <div
                                        class="d-flex align-center"
                                        style="max-width: 100%; max-height: 100%;"
                                      >
                                        <img
                                          v-if="item.image == null"
                                          src="@/assets/job.png"
                                          alt="image"
                                          style="max-width: 100%; max-height: 100%;"
                                        />
                                        <img
                                          v-if="item.image"
                                          :src="item.image"
                                          alt="Image on License"
                                          style="max-width: 100%; max-height: 100%;"
                                        />
                                      </div>
                                    </v-card-text>

                                    <v-divider></v-divider>

                                    <v-card-actions>
                                      <v-spacer></v-spacer>
                                      <v-btn color="primary" text @click="dialogL = false">Close</v-btn>
                                    </v-card-actions>
                                  </v-card>
                                </v-dialog>
                              </div>
                            </template>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
                    </v-card>
                  </v-col>
                </v-row>
              </template>
            </v-data-iterator>
          </v-card>
        </base-material-card>
      </v-col>

      <v-col cols="4">
        <base-material-card color="primary">
          <template v-slot:heading>
            <div class="text-h3 font-weight-light" style="display: flex; align-items: center;">
              <span style="margin-right: 10px;">Insurance Details</span>
              <v-spacer />
              <v-icon @click="driverInsuranceDialog = true">mdi-pencil</v-icon>
            </div>
          </template>
          <v-card v-if="userDetails" elevation="0" class="mt-n6">
            <v-data-iterator
              :items="insuranceDetailTable"
              :items-per-page="5"
              hide-default-footer
            >
              <template v-slot:default="props">
                <v-row>
                  <v-col v-for="item in props.items" :key="item.name" cols="12">
                    <v-card elevation="0">
                      <v-list dense>
                        <v-list-item>
                          <v-list-item-content>
                            Insurance Coverage:
                          </v-list-item-content>
                          <v-list-item-content class="align-end">
                            {{
                              (item.insuranceCoverage && item.insuranceCoverage.length > 0)
                              ? item.insuranceCoverage
                                .toString()
                                .substring(
                                  item.insuranceCoverage.length - 3
                                )
                              : null
                            }}
                          </v-list-item-content>
                        </v-list-item>

                        <v-list-item>
                          <v-list-item-content>
                            Verification Status:
                          </v-list-item-content>
                          <v-list-item-content class="align-end">
                            {{ item.isVerified }}
                          </v-list-item-content>
                        </v-list-item>

                        <v-list-item>
                          <v-list-item-content>
                            Expiry Date:
                          </v-list-item-content>
                          <v-list-item-content class="align-end">
                            {{ getReadableDate(item.expiryDate) }}
                          </v-list-item-content>
                        </v-list-item>

                        <v-list-item>
                          <v-list-item-content>
                            Image on Insurance:
                          </v-list-item-content>
                          <v-list-item-content class="align-end">
                            <template>
                              <div class="text-center">
                                <v-dialog v-model="dialogI" width="500">
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                      style="margin-left: -80px"
                                      outlined
                                      small
                                      fab
                                      color="green"
                                      v-bind="attrs"
                                      v-on="on"
                                    >
                                      <v-icon>mdi-attachment</v-icon>
                                    </v-btn>
                                  </template>

                                  <v-card class="d-flex flex-column">
                                    <v-card-title class="text-h5 grey lighten-2">
                                      Image
                                    </v-card-title>

                                    <v-card-text class="d-flex justify-center align-center">
                                      <div
                                        class="d-flex align-center"
                                        style="max-width: 100%; max-height: 100%;"
                                      >
                                        <img
                                          v-if="item.image == null"
                                          src="@/assets/job.png"
                                          alt="image"
                                          style="max-width: 100%; max-height: 100%;"
                                        />
                                        <img
                                          v-if="item.image"
                                          :src="item.image"
                                          alt="image"
                                          style="max-width: 100%; max-height: 100%;"
                                        />
                                      </div>
                                    </v-card-text>

                                    <v-divider></v-divider>

                                    <v-card-actions>
                                      <v-spacer></v-spacer>
                                      <v-btn color="primary" text @click="dialogI = false">Close</v-btn>
                                    </v-card-actions>
                                  </v-card>

                                </v-dialog>
                              </div>
                            </template>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
                    </v-card>
                  </v-col>
                </v-row>
              </template>
            </v-data-iterator>
          </v-card>
        </base-material-card>
      </v-col>
    </v-row>

    <!-- requested to remove this ft 22122023     -->
    <!-- <template>
      <v-row justify="start" align="center" v-if="userDetails && userDetails.is_internal">
        <v-col cols="6">
          <base-material-card color="primary">
            <template v-slot:heading>
              <div class="text-h3 font-weight-light d-flex align-center">
                <span class="mr-2">Driver Location</span>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on">mdi-information-outline</v-icon>
                  </template>
                  <span class="font-weight-light">
                    This section is designed for internal drivers to provide periodic updates on their location during a
                    ride. (Temporary Feature)
                  </span>
                </v-tooltip>
              </div>
            </template>
            <v-card class="elevation-12" shaped>
              <v-card-text>
                <v-form ref="form">
                  <v-row>
                    <v-col cols="12" class="mt-4">
                      <v-text-field v-model="latitude" label="Latitude" outlined required dense></v-text-field>
                    </v-col>
                    <v-col cols="12" class="mt-2">
                      <v-text-field v-model="longitude" label="Longitude" outlined required dense></v-text-field>
                    </v-col>
                  </v-row>
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-btn @click="updateLocation" color="primary">Update</v-btn>
              </v-card-actions>
            </v-card>
          </base-material-card>
        </v-col>
      </v-row>
    </template> -->

    <v-dialog v-model="driverBusinessDialog" max-width="600px">
      <v-card>
        <v-card-title>
          <span class="text-h5">
            Edit Business
          </span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  v-model="business.businessName"
                  label="Business Name"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  v-model="business.businessAddress"
                  label="Business Address"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  v-model="business.city"
                  label="Business City"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  v-model="business.postCode"
                  label="Business Post Code"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="12">
                <v-text-field
                  v-model="business.link"
                  label="Business Link"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="driverBusinessDialog = false"
          >
            Cancel
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="saveBusiness()"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="driverInsuranceDialog" max-width="600px">
      <v-card>
        <v-card-title>
          <span class="text-h5">
            Edit Insurance
          </span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="6" md="6">
                <v-select
                    v-model="insurance.isVerified"
                    :items="[true, false]"
                    item-text="label"
                    item-value="value"
                    label="Insurance Verified"
                  />
                <!-- <v-text-field
                  v-model="insurance.isVerified"
                  label="Insurance Verified"
                ></v-text-field> -->
              </v-col>
              <!-- <v-col cols="12" sm="6" md="6">
                <v-text-field
                  v-model="insurance.expiryDate"
                  label="Insurance ExpiryDate"
                ></v-text-field>
              </v-col> -->
              
              <v-col cols="12" sm="6" md="6">
                <v-dialog
                  ref="dialog"
                  v-model="dateModal"
                  :return-value.sync="insurance.expiryDate"
                  persistent
                  width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="insurance.expiryDate"
                      label="Expiry Date"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    />
                  </template>
                  <v-date-picker v-model="insurance.expiryDate" scrollable>
                    <v-spacer />
                    <v-btn text color="primary" @click="dateModal = false">
                      Cancel
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.dialog.save(insurance.expiryDate)"
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-dialog>
              </v-col>
              


              <v-col cols="12" sm="12" md="12">
                  <!-- <v-text-field
                    v-model="insurance.insuranceCoverage"
                    label="Insurance Coverage"
                  ></v-text-field> -->
                  <div>Insurance Coverage</div>
                  <v-row align="start" class="checkbox-row">
                    <v-col cols="4">
                      <v-checkbox v-model="selectedInsuranceItems" label="Vehicle Transportation" value="Vehicle Transportation" class="checkbox-align-top"></v-checkbox>
                    </v-col>
                    <v-col cols="4">
                      <v-checkbox v-model="selectedInsuranceItems" label="Breakdown Recovery" value="Breakdown Recovery" class="checkbox-align-top"></v-checkbox>
                    </v-col>
                    <v-col cols="4">
                      <v-checkbox v-model="selectedInsuranceItems" label="Carried Passengers" value="Carried Passengers" class="checkbox-align-top"></v-checkbox>
                    </v-col>
                  </v-row>
                  <!-- <div>Selected Items: {{ selectedInsuranceItems }}</div> -->
              </v-col>

              <v-col cols="12" sm="12" md="12">
                <v-file-input
                  v-model="insurance.image"
                  :rules="[
                    (value) =>
                      !value ||
                      value.size < 2000000 ||
                      'Image size should be less than 2 MB!',
                  ]"
                  accept="image/png, image/jpeg, image/bmp"
                  placeholder="Pick an Image"
                  prepend-icon="mdi-camera"
                  label="Insurance Image"
                />
                <div v-if="insuranceImage">
                  <img :src="insuranceImage" style="max-width: 100%; max-height: 300px; margin-top: 10px;" />
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="cancelInsuranceEdit"
          >
            Cancel
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="saveInsurance()"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="driverLicenseDialog" max-width="600px">
      <v-card>
        <v-card-title>
          <span class="text-h5">
            Edit License
          </span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="6" md="6">
                <v-select
                    v-model="license.isVerified"
                    :items="[true, false]"
                    item-text="label"
                    item-value="value"
                    label="License Verified"
                  />
              </v-col>

              <v-col cols="12" sm="6" md="6">
                <v-dialog
                  ref="dialog"
                  v-model="dateModalLicense"
                  :return-value.sync="license.expiryDate"
                  persistent
                  width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="license.expiryDate"
                      label="Expiry Date"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    />
                  </template>
                  <v-date-picker v-model="license.expiryDate" scrollable>
                    <v-spacer />
                    <v-btn text color="primary" @click="dateModalLicense = false">
                      Cancel
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.dialog.save(license.expiryDate)"
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-dialog>
              </v-col>
              


              <v-col cols="12" sm="12" md="12">
                <v-text-field
                  v-model="license.licenseNumber"
                  label="License Number"
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="12" md="12">
                <v-file-input
                  v-model="license.image"
                  :rules="[
                    (value) =>
                      !value ||
                      value.size < 2000000 ||
                      'Image size should be less than 2 MB!',
                  ]"
                  accept="image/png, image/jpeg, image/bmp"
                  placeholder="Pick an Image"
                  prepend-icon="mdi-camera"
                  label="License Image"
                />
                <div v-if="licenseImage">
                  <img :src="licenseImage" style="max-width: 100%; max-height: 300px; margin-top: 10px;" />
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="cancelLicenseEdit"
          >
            Cancel
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="saveLicense()"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>


  </v-container>
</template>
<script>
import {
  getDriver,
  getSingleUserMeetingStats,
  getUserEarning,
  getInvitedUsers,
  getIsUserMakeFirstBooking,
  updateFeatured,
  getUserSingleMetadata,
  updateUser,
  getDriverVehicle,
  getExperienceByID,
  updateUserEducation,
  updateUserExperience,
  createUserEducation,
  createUserExperience,
  deleteUserExperience,
  deleteUserEducation,
} from "@/services/users";
import {
  addPhoto,
  addNewDriverVehicle,
  updateDriverVehicle,
  getAllDriverRecentBooking,
  deleteDriverVehicle,
  beingAdminUpdateDriver,
  getLicenseDetails,
  updateDriverProfile,
  uploadImage,
  updateDriverLocation,
} from "@/services/admin";
import Icons from "../dashboard/component/Icons.vue";
import VComponent from "../../components/base/VComponent.vue";
import DateFilter from "@/components/dateFilter";
import { http } from "@/services/http-common";
import firebaseDB from "../../firebaseInit";
import { getDatabase, ref, onValue } from "firebase/database";
import axios from 'axios'



export default {
  components: { Icons, VComponent, DateFilter },
  data() {
    return {
      driverBusinessDialog: false,
      business: {},

      driverInsuranceDialog: false,
      insurance: {
        expiryDate: new Date().toISOString().split('T')[0]
      },

      driverLicenseDialog: false,
      license: {
        expiryDate: new Date().toISOString().split('T')[0]
      },

      dateModal: false,
      dateModalLicense: false,

      latitude: '',
      longitude: '',

      selectedNewAvatarImage: null,
      generealDetailsSaveBtnLd: false,
      selectedInsuranceItems: [],
      insuranceImageServerURL: null,
      licenseImageServerURL: null,
      // location:
      dialog: false,
      dialogL: false,
      dialogI: false,
      menu: false,
      menuE: false,
      load: false,
      showLoad: false,
      timeStatus: "all",
      activePicker: null,
      activePickerE: null,
      menuexp: false,
      menuexpend: false,
      activePickerexp: null,
      activePickerexpend: null,
      dialogDelete: false,
      headers: [
        {
          text: "Pickup Address",
          value: "pickupAdr",
          sortable: false,
          width: "100px",
        },
        {
          text: "Dropoff Address",
          value: "dropoffAdr",
          width: "100px",
          sortable: false,
        },
        // {
        //   text: "Title",
        //   align: "start",
        //   sortable: false,
        //   value: "title",
        //   width: "100px",
        // },

        {
          text: "Vehicle Make",
          value: "vehicleMake",
          width: "100px",
          sortable: false,
        },
        {
          text: "Registration No#",
          value: "vehicleRegistration",
          width: "100px",
          sortable: false,
        },

        // { text: 'Employement Type', value: 'employement_type', width: '170px', sortable: false },
        {
          text: "Status",
          value: "rideStatus",
          width: "100px",
          sortable: false,
        },
        {
          text: "Start Date",
          value: "createdAt",
          width: "100px",
          sortable: false,
        },
        // {
        //   text: "End Date",
        //   value: "end_date",
        //   width: "100px",
        //   sortable: false,
        // },
        // {
        //   text: "Currently Working",
        //   value: "current_job",
        //   width: "150px",
        //   sortable: false,
        // },
        // {
        //   text: "Description",
        //   value: "description",
        //   width: "190px",
        //   sortable: false,
        // },
        // { text: "Actions", value: "actions", sortable: false },
      ],
      desserts: [],
      bookings: [],
      licenceDetailTable: [],
      insuranceDetailTable: [],
      businessDetailTable: [],
      editedIndex: -1,
      editedItem: {
        id: "",
        title: "",
        image_exp: "",
        job: "",
        location: "",
        company: "",
        employement_type: "",
        current_job: "",
        status: "",
        start_date: "",
        end_date: "",
        description: "",
      },
      defaultItem: {
        id: "",
        title: "",
        image_exp: "",
        job: "",
        location: "",
        company: "",
        employement_type: "",
        current_job: "",
        status: "",
        start_date: "",
        end_date: "",
        description: "",
      },
      dialogEdu: false,
      dialogDeleteEdu: false,
      headersEdu: [
        {
          text: "Make",
          align: "start",
          sortable: false,
          value: "make",
          width: "120px",
        },
        {
          text: "Registration",
          value: "registration",
          sortable: false,
          width: "150px",
        },
        { text: "Type", value: "type", sortable: false, width: "140px" },
        {
          text: "Max Passengers",
          value: "maxPassengers",
          sortable: false,
          width: "150px",
        },
        {
          text: "Created At",
          value: "createdAt",
          width: "140px",
          sortable: false,
        },
        {
          text: "updated At",
          value: "updatedAt",
          width: "140px",
          sortable: false,
        },
        { text: "Actions", value: "actions", sortable: false, width: "100px" },
      ],
      eduDetails: [],
      editedIndexEdu: -1,
      editedItemEdu: {
        id: "",
        make: "",
        registration: "",
        type: " ",
        maxPassengers: " ",
        createdAt: "",
        updatedAt: "",
      },
      defaultItemEdu: {
        id: "",
        make: "",
        registration: "",
        type: " ",
        maxPassengers: " ",
        createdAt: "",
        updatedAt: "",
      },
      dialogInvt: false,
      dialogDeleteInvt: false,
      headersInvt: [
        {
          text: "First Name",
          align: "start",
          sortable: false,
          value: "firstName",
          width: "100px",
        },
        {
          text: "Last Name",
          align: "start",
          sortable: false,
          value: "lastName",
          width: "100px",
        },
        {
          text: "Email",
          align: "start",
          sortable: false,
          value: "email",
          width: "140px",
        },
        {
          text: "First Booking",
          align: "start",
          sortable: false,
          value: "firstBooking",
          width: "140px",
        },
        { text: "Actions", value: "actions", sortable: false, width: "100px" },
      ],
      InvtDetails: [],
      editedIndexInvt: -1,
      editedItemInvt: {
        id: "",
        firstName: "",
        lastName: "",
        email: "",
        firstBooking: "",
      },
      defaultItemInvt: {
        id: "",
        firstName: "",
        lastName: "",
        email: "",
        firstBooking: "",
      },
      userDetails: null,
      userStats: null,
      userEarning: null,
      userEdu: null,
      userInvt: null,
      userExp: null,
      userBooking: null,
      driverProfile: null,
      showFileInput: false,
      showFileInputEdu: false,
      showFileInputExp: false,
      userDetailsEdit: false,
      dateFilter: null,
      initDate: [],
      headersLicense: [
        {
          text: "License Image",
          value: "image",
          sortable: false,
          width: "100px",
        },
        {
          text: "License Number",
          value: "licenseNumber",
          width: "100px",
          sortable: false,
        },
        {
          text: "License Expiry Date",
          value: "expiryDate",
          width: "100px",
          sortable: false,
        },
        {
          text: "Verification Status",
          value: "isVerified",
          width: "100px",
          sortable: false,
        },
      ],

      headersInsurance: [
        {
          text: "Image",
          value: "image",
          sortable: false,
          width: "100px",
        },
        {
          text: "Insurance Coverage",
          value: "insuranceCoverage",
          width: "100px",
          sortable: false,
        },
        {
          text: "Expiry Date",
          value: "expiryDate",
          width: "100px",
          sortable: false,
        },
        {
          text: "Verification Status",
          value: "isVerified",
          width: "100px",
          sortable: false,
        },
      ],

      headersBusiness: [
        {
          text: "Business Name",
          value: "businessName",
          sortable: false,
          width: "100px",
        },
        {
          text: "Business Address",
          value: "businessAddress",
          width: "100px",
          sortable: false,
        },
        {
          text: "City",
          value: "city",
          width: "100px",
          sortable: false,
        },
        {
          text: "Post Code",
          value: "postCode",
          width: "100px",
          sortable: false,
        },
      ],
    };
  },
  computed: {
    formTitleEdu() {
      return this.editedIndexEdu === -1 ? "New Item" : "Edit Item";
    },
    formTitleInvt() {
      return this.editedIndexEdu === -1 ? "New Item" : "Edit Item";
    },
    formTitle() {
      return this.editedIndex === -1 ? "New Item" : "Edit Item";
    },
    customDetails() {
      // console.log(this.userDetails);
      return {
        profile_photo: this.userDetails.avatar,
        firstname: this.userDetails.firstName,
        lastname: this.userDetails.surName,
        email: this.userDetails.email,
        phone: this.userDetails.phone,
        type: this.userDetails.role,
        status: this.userDetails.isVerified ? "Approved" : "Pending",
        // is_featured: this.userDetails.is_featured,
        login_type: this.userDetails.method,
        wallet: this.userDetails.wallet.balance,
        licenseNumber: this.userDetails?.profile?.driver?.license?.licenseNumber
          ? this.userDetails?.profile?.driver?.license?.licenseNumber
          : "Missing",
        yearsOfExperience: this.userDetails.yearsOfExperience,
        // licenseImage: this.userDetails.profile.driver.license.image,
        // licenseExpiry: this.userDetails.profile.driver.license.expiryDate,
        // licenseVerificationStatus: ((this.userDetails.profile.driver.license.isVerified) ? 'Verified' : 'Not Verified')
        // bio: this.userDetails.bio,
        company: this.userDetails.company,
      };
    },
    insuranceImage() {
      if (this.insurance.image instanceof File) {
        return URL.createObjectURL(this.insurance.image);
      } else if (typeof this.insurance.image === 'string') {
        // Assuming this.insurance.image holds the URL from the server
        return this.insurance.image;
      } else {
        return null;
      }
    },
    licenseImage() {
      if (this.license.image instanceof File) {
        return URL.createObjectURL(this.license.image);
      } else if (typeof this.license.image === 'string') {
        // Assuming this.license.image holds the URL from the server
        return this.license.image;
      } else {
        return null;
      }
    }
  },
  watch: {
    menu(val) {
      val && setTimeout(() => (this.activePicker = "YEAR"));
    },
    menuE(val) {
      val && setTimeout(() => (this.activePickerE = "YEAR"));
    },
    menuexp(val) {
      val && setTimeout(() => (this.activePickerexp = "YEAR"));
    },
    menuexpend(val) {
      val && setTimeout(() => (this.activePickerexpend = "YEAR"));
    },
    dialogEdu(val) {
      val || this.closeEdu();
    },
    dialogDeleteEdu(val) {
      val || this.closeDeleteEdu();
    },
    dialogInvt(val) {
      val || this.closeInvt();
    },
    dialogDeleteInvt(val) {
      val || this.closeDeleteInvt();
    },
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    timeStatus() {
      this.getUsersTimeStats();
    },
  },
  mounted() {
    // Find data using the "bookings" reference
    const db = getDatabase();
    const starCountRef = ref(db, `${process.env.VUE_APP_Path_Firebase ? process.env.VUE_APP_Path_Firebase : ''}` + '/bookings/');
    onValue(starCountRef, (snapshot) => {
      const data = snapshot.val();
      console.log("🚀 ~ file: profile.vue:2086 ~ snapshot.forEach ~ childSnapshot.val():", data)

    });

    this.fetchDriverData();
    // getUserSingleMetadata(this.$route.params.id).then((res) => {
    //   if (res.data.is_featured == 1) {
    //     res.data['is_featured'] = true
    //   } else {
    //     res.data['is_featured'] = false
    //   }
    //   this.userDetails = { ...res.data, ...this.userDetails }
    //   console.log('====================================')
    //   console.log("is featured",this.userDetails.is_featured)
    //   console.log('====================================')
    // })
    this.getUsersTimeStats();
    // getSingleUserMeetingStats(this.$route.params.id).then((res) => {
    //   this.userStats = res.data;
    // });

    getAllDriverRecentBooking(this.$route.params.id).then((res) => {
      this.userBooking = res.data;
      // console.log('bookings', res.data)
      this.userBooking.forEach((ele) => {
        const row = {
          pickupAdr: ele.pickup.address,
          dropoffAdr: ele.dropoff.address,
          vehicleMake: ele.vehicleMake,
          vehicleRegistration: ele.vehicleRegistration,
          createdAt: ele.createdAt,
          rideStatus: ele.status,
        };
        this.bookings.push(row);
      });
    });

    getLicenseDetails(this.$route.params.id).then((res) => {
      this.driverProfile = res.data.driver[0];

      const licenseRow = {
        licenseNumber: this.driverProfile?.profile?.driver?.license?.licenseNumber || null,
        image: this.driverProfile?.profile?.driver?.license?.image || null,
        expiryDate: this.driverProfile?.profile?.driver?.license?.expiryDate || new Date().toISOString().split('T')[0],
        isVerified: this.driverProfile?.profile?.driver?.license?.isVerified || null
          ? "Verified"
          : "Not Verified",
      };
      this.licenseImageServerURL = this.driverProfile?.profile?.driver?.license?.image || null;
      this.licenceDetailTable.push(licenseRow);

      const insuranceRow = {
        insuranceCoverage:
          this.driverProfile?.profile?.driver?.insurance?.insuranceCoverage || null,
        image: this.driverProfile?.profile?.driver?.insurance?.image || null,
        expiryDate: this.driverProfile?.profile?.driver?.insurance?.expiryDate || new Date().toISOString().split('T')[0],
        isVerified: this.driverProfile?.profile?.driver?.insurance?.isVerified || null
          ? "Verified"
          : "Not Verified",
      };
      this.insuranceImageServerURL = this.driverProfile?.profile?.driver?.insurance?.image || null;
      this.selectedInsuranceItems = this.driverProfile?.profile?.driver?.insurance?.insuranceCoverage || [];
      this.insuranceDetailTable.push(insuranceRow);

      // console.log('insuranceRow', insuranceRow, 'licenseRow', licenseRow);
      const businessRow = {
        businessName: this.driverProfile?.profile?.driver?.business?.businessName || null,
        businessAddress:
          this.driverProfile?.profile?.driver?.business?.businessAddress || null,
        city: this.driverProfile?.profile?.driver?.business?.city || null,
        postCode: this.driverProfile?.profile?.driver?.business?.postCode || null,
        link: this.driverProfile?.profile?.driver?.business?.link || null,
      };
      this.businessDetailTable.push(businessRow);
    });
    // getInsuranceDetails(this.$route.params.id).then((res) => {
    //   this.driverProfile = res.data.driver[0]
    //   const row = {
    //     licenseNumber: this.driverProfile.profile.driver.license.licenseNumber,
    //     image: this.driverProfile.profile.driver.license.image,
    //     expiryDate: this.driverProfile.profile.driver.license.expiryDate,
    //     isVerified: this.driverProfile.profile.driver.license.isVerified
    //       ? 'Verified'
    //       : 'Not Verified',
    //   }
    //   this.insuranceDetailTable.push(row)
    // })
    // getBusinessDetails(this.$route.params.id).then((res) => {
    //   this.driverProfile = res.data.driver[0]
    //   const row = {
    //     licenseNumber: this.driverProfile.profile.driver.license.licenseNumber,
    //     image: this.driverProfile.profile.driver.license.image,
    //     expiryDate: this.driverProfile.profile.driver.license.expiryDate,
    //     isVerified: this.driverProfile.profile.driver.license.isVerified
    //       ? 'Verified'
    //       : 'Not Verified',
    //   }
    //   this.businessDetailTable.push(row)
    // })

    // getExperienceByID(this.$route.params.id).then((res) => {
    //   this.userExp = res.data
    //   console.log('11', res.data)
    //   this.userExp.forEach((ele) => {
    //     const row = {
    //       id: ele.id,
    //       title: ele.title,
    //       image_exp: ele.profile_photo,
    //       job: ele.job,
    //       location: ele.location,
    //       company: ele.company,
    //       employement_type: ele.employement_type,
    //       current_job: this.setcurrent_job(ele.current_job),
    //       status: ele.status,
    //       start_date: this.getReadableDate(ele.start_date),
    //       end_date: ele.end_date ? this.getReadableDate(ele.end_date) : null,
    //       description: ele.description,
    //     }
    //     this.desserts.push(row)
    //   })
    // })
    getDriverVehicle(this.$route.params.id).then((res) => {
      this.userEdu = res.data.DriverVehicle;
      // console.log(this.userEdu, 'fasdfasdfasdfsadfsdfsdf')
      this.userEdu.forEach((element) => {
        const row = {
          id: element._id,
          make: element.make,
          registration: element.registration,
          maxPassengers: element.maxPassengers,
          type: element.type,
          createdAt: element.createdAt
            ? this.getReadableDate(element.createdAt)
            : null,
          updatedAt: element.updatedAt
            ? this.getReadableDate(element.updatedAt)
            : null,
        };
        this.eduDetails.push(row);
      });
    });
    // getInvitedUsers(this.$route.params.id).then(async (res) => {
    //   this.userInvt = res.data.usersArray
    //   this.userInvt.forEach((element) => {
    //     const row = {
    //       id: element.dataValues.id,
    //       firstName: element.dataValues.first_name,
    //       lastName: element.dataValues.last_name,
    //       email: element.dataValues.email,
    //       firstBooking: element.isBooking ? 'No' : 'Yes',
    //     }
    //     this.InvtDetails.push(row)
    //   })
    // })
    // getUserEarning(this.$route.params.id).then((res) => {
    //   this.userEarning = res.data;
    // });
  },


  methods: {
    async fetchDriverData() {
      getDriver(this.$route.params.id).then((res) => {

        this.userDetails = res.data.driver[0];
        this.business = res.data.driver[0]?.profile?.driver?.business || {};
        this.insurance = res.data.driver[0]?.profile?.driver?.insurance || {};
        this.insurance.expiryDate = res.data.driver[0]?.profile?.driver?.insurance ? new Date(res.data.driver[0]?.profile?.driver?.insurance?.expiryDate).toISOString().split('T')[0] : new Date().toISOString().split('T')[0]
        this.license = res.data.driver[0]?.profile?.driver?.license || {};
        this.license.expiryDate = res.data.driver[0]?.profile?.driver?.license ? new Date(res.data.driver[0]?.profile?.driver?.license?.expiryDate).toISOString().split('T')[0] : new Date().toISOString().split('T')[0]

        this.longitude = res.data.driver[0]?.location?.coordinates[0]
        this.latitude = res.data.driver[0]?.location?.coordinates[1]
        // this.insurance = res.data.driver[0]?.profile?.driver?.insurance;
        // console.log(this.userDetails, 'its ok not to be ok')
      });
    },

    async updateLocation() {
      try {
        const data = {
          latitude: this.latitude,
          longitude: this.longitude,
        };
        const response = await updateDriverLocation(this.$route.params.id, data);

        // Check if the update was successful
        if (response.status === 200) {
          this.fetchDriverData();

          // Show success alert
          this.$snackbar.show({
            message: 'Location updated successfully',
            color: 'success',
          });
        } else {
          throw new Error('Failed to update location');
        }
      } catch (error) {
        console.error('Error updating driver location:', error);

        // Show error alert
        this.$snackbar.show({
          message: 'Error updating location. Please try again.',
          color: 'error',
        });
      }
    },

    cancelLicenseEdit() {
      this.license.image = this.licenseImageServerURL
      this.driverLicenseDialog = false
    },
    cancelInsuranceEdit() {
      this.insurance.image = this.insuranceImageServerURL
      this.driverInsuranceDialog = false
    },
    openFileInput() {
      this.$refs.avatarFileInput.click(); // Trigger the hidden file input for the avatar
    },
    onAvatarFileChange(event) {
      const file = event.target.files[0]; // Get the selected file from the avatar file input
      // Handle the file for the avatar separately here
      // For now, we will set it to the customDetails.profile_photo to display it in the avatar
      if (file) {
        this.selectedNewAvatarImage = file;
        // console.log('%cprofile.vue line:2435 customDetails.profile_photo', 'color: #007acc;', this.customDetails.profile_photo, file);
        const reader = new FileReader();
        reader.onload = (e) => {
          // console.log('e.target', e.target, e);
          this.customDetails.profile_photo = e.target.result;

          //below two lines using just a hack to rerender image because it was failing to rerender
          this.userDetailsEdit = false;
          this.userDetailsEdit = true;
          // console.log('called', this.customDetails.profile_photo, e.target.result);


        };
        reader.readAsDataURL(file);
      }
    },

    onDateChange(newValue) {
      if (newValue.length < 2) return;
      this.dateFilter = newValue;
      this.loadUserStats();
    },
    getUsersTimeStats() {
      this.load = true;
      this.showLoad = true;
      const userStatsParams = {
        status: this.timeStatus === "all" ? null : this.timeStatus,
        id: this.$route.params.id,
      };
      // getUserEarning(userStatsParams).then((res) => {
      //   this.userEarning = res.data
      //   this.userStats = res.data
      //   console.log('test', this.userEarning)
      //   this.load = false
      //   this.showLoad = false
      // })
      // getSingleUserMeetingStats(userStatsParams).then((res) => {
      //   this.userStats = res.data
      //   this.showLoad = false
      // })
      this.showLoad = false;
    },
    loadUserStats() {
      http
        .get(
          `${process.env.VUE_APP_API}/earning/2/?start=${this.dateFilter[0]}&end=${this.dateFilter[1]}`
        )
        .then((res) => {
          this.userEarning = res.data;
          this.userStats = res.data;
        });
    },
    initialize() {
      this.userExp.forEach((ele) => {
        const row = {
          id: ele.id,
          title: ele.title,
          image: ele.profile_photo,
          job: ele.job,
          location: ele.location,
          company: ele.company,
          employement_type: ele.employement_type,
          current_job: this.setcurrent_job(ele.current_job),
          status: ele.status,
          start_date: ele.start_date,
          end_date: ele.end_date,
          description: ele.description,
        };
        this.desserts.push(row);
      });
    },

    editItem(item) {
      this.editedIndex = this.desserts.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.desserts.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm(id) {
      this.desserts.splice(this.editedIndex, 1);
      deleteUserExperience(id).then((userExpres) => {
        alert("User Record Deleted Successfully!");
      });
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      if (this.editedIndex > -1) {
        const formDataExp = new FormData();
        Object.assign(this.desserts[this.editedIndex], this.editedItem);
        formDataExp.append("file", this.editedItem.image_exp);
        formDataExp.append("experience_id", this.editedItem.id);
        this.editedItem.current_job = this.editedItem.current_job ? "1" : "0";
        // console.log(this.editedItem.current_job)
        updateUserExperience(this.editedItem.id, this.editedItem).then(
          (userExpres) => {
            addPhoto("experience", formDataExp).then((res) => {
              window.location.reload();
            });
            alert("User Record Updated Successfully!");
          }
        );
      } else {
        this.desserts.push(this.editedItem);
        const formDataExpNew = new FormData();
        const newExp = {
          user_id: this.$route.params.id,
          title: this.editedItem.title,
          job: this.editedItem.job,
          image: this.editedItem.image_exp,
          location: this.editedItem.location,
          company: this.editedItem.company,
          employement_type: this.editedItem.employement_type,
          current_job: this.editedItem.current_job,
          status: this.editedItem.status,
          start_date: this.editedItem.start_date,
          end_date: this.editedItem.current_job
            ? null
            : this.editedItem.end_date,
          description: this.editedItem.description,
        };
        createUserExperience(newExp).then((userExpres) => {
          formDataExpNew.append("file", newExp.image);
          formDataExpNew.append("experience_id", userExpres.data.id);
          // console.log(this.editedItemEdu.image_edu)
          addPhoto("experience", formDataExpNew).then((res) => {
            window.location.reload();
          });
          alert("User Record Updated Successfully!");
        });
      }
      this.close();
    },
    initializeEdu() {
      this.userEdu.forEach((element) => {
        const row = {
          school: element.school,
          field_of_study: element.field_of_study,
          degree: element.degree,
          status: element.status,
          start_date: element.start_Date,
          end_date: element.end_Date,
          description: element.description,
        };
        // console.log(row)
        this.eduDetails.push(row);
      });
    },
    // initializeInvt() {
    //   this.userInvt.forEach((element) => {
    //     const row = {
    //       firstName: element.first_name,
    //       lastName: element.last_name,
    //       email: element.email,
    //       firstBooking: this.isUserMakeFirstBooking(element.id),
    //     }
    //     console.log(row)
    //     this.InvtDetails.push(row)
    //   })
    // },

    editItemEdu(item) {
      this.editedIndexEdu = this.eduDetails.indexOf(item);
      this.editedItemEdu = Object.assign({}, item);
      this.dialogEdu = true;
    },
    editItemInvt(item) {
      this.editedIndexInvt = this.InvtDetails.indexOf(item);
      this.editedItemInvt = Object.assign({}, item);
      this.dialogInvt = true;
    },
    goToProfile(id) {
      this.$router.push({ name: "Customer Profile", params: { id: id } });
      window.location.reload();
    },
    deleteItemEdu(item) {
      this.editedIndexEdu = this.eduDetails.indexOf(item);
      this.editedItemEdu = Object.assign({}, item);
      this.dialogDeleteEdu = true;
    },
    deleteItemInvt(item) {
      this.editedIndexInvt = this.InvtDetails.indexOf(item);
      this.editedItemInvt = Object.assign({}, item);
      this.dialogDeleteInvt = true;
    },

    //#PE
    //for deletion of vehice in user profile view,
    //not messing with the variables(not modifying their names, may be used somewhere else as well)
    vehicleDeleteConfirm(id) {
      // console.log(id)
      this.eduDetails.splice(this.editedIndexEdu, 1);
      deleteDriverVehicle(id).then((userExpres) => {
        alert("User Record Deleted Successfully!");
      });
      this.closeDeleteEdu();
    },

    //----end PE
    deleteItemConfirmInvt(id) {
      // console.log(id)
      this.InvtDetails.splice(this.editedIndexInvt, 1);
      deleteUserEducation(id).then((userExpres) => {
        alert("User Record Deleted Successfully!");
      });
      this.closeDeleteInvt();
    },

    closeEdu() {
      this.dialogEdu = false;
      this.$nextTick(() => {
        this.editedItemEdu = Object.assign({}, this.defaultItemEdu);
        this.editedIndexEdu = -1;
      });
    },
    closeInvt() {
      this.dialogInvt = false;
      this.$nextTick(() => {
        this.editedItemInvt = Object.assign({}, this.defaultItemInvt);
        this.editedIndexInvt = -1;
      });
    },

    closeDeleteEdu() {
      this.dialogDeleteEdu = false;
      this.$nextTick(() => {
        this.editedItemEdu = Object.assign({}, this.defaultItemEdu);
        this.editedIndexEdu = -1;
      });
    },
    closeDeleteInvt() {
      this.dialogDeleteInvt = false;
      this.$nextTick(() => {
        this.editedItemInvt = Object.assign({}, this.defaultItemInvt);
        this.editedIndexInvt = -1;
      });
    },

    saveInvt() {
      if (this.editedIndexInvt > -1) {
        Object.assign(
          this.InvtDetails[this.editedIndexInvt],
          this.editedItemInvt
        );
        alert("saved!");
      } else {
        this.InvtDetails.push(this.editedItemInvt);
        alert("saved!");
      }
      this.closeInvt();
    },

    async saveEdu() {
      if (this.editedIndexEdu > -1) {
        const formDataEdu = new FormData();
        Object.assign(this.eduDetails[this.editedIndexEdu], this.editedItemEdu);
        // formDataEdu.append("file", this.editedItemEdu.image_edu);
        // console.log(this.editedItemEdu.image_edu);
        // formDataEdu.append("education_id", this.editedItemEdu.id);
        // console.log("formdata", formDataEdu);

        const updateData = {
          vehicleMake: this.editedItemEdu.make,
          type: this.editedItemEdu.type,
          vehicleRegistration: this.editedItemEdu.registration,
          maxPassengers: this.editedItemEdu.maxPassengers,
        };
        updateDriverVehicle(this.editedItemEdu.id, updateData).then(
          (userEduRes) => {
            // addPhoto("education", formDataEdu).then((res) => {
            //   window.location.reload();
            // });
            alert("User Record Updated Successfully!");
          }
        );
      } else {
        //new vehicle saving
        const newVehicle = {
          driver_id: this.$route.params.id,
          vehicleMake: this.editedItemEdu.make,
          type: this.editedItemEdu.type,
          vehicleRegistration: this.editedItemEdu.registration,
          maxPassengers: this.editedItemEdu.maxPassengers,
        };
        // console.log(newEdu);
        await addNewDriverVehicle(newVehicle).then((response) => {
          this.editedItemEdu.createdAt = this.getReadableDate(
            response.data.vehicle.createdAt
          );
          this.editedItemEdu.updatedAt = this.getReadableDate(
            response.data.vehicle.updatedAt
          );
          alert("User Vehicle Added Successfully!");
        });
        this.eduDetails.push(this.editedItemEdu);
      }
      this.closeEdu();
    },
    getReadableDate(date) {
      const d = new Date(date).toUTCString();
      return date.substr(0, 10);
    },
    setcurrent_job(current_job) {
      if (current_job == 1) {
        return true;
      } else {
        return false;
      }
    },
    addProfilePhoto() {
      this.showFileInput = true;
    },
    addPhotoEdu() {
      this.showFileInputEdu = true;
    },
    addPhotoExp() {
      this.showFileInputExp = true;
    },
    enableEditUserDetail() {
      this.userDetailsEdit = true;
    },

    updateAction() {
      const updateData = {
        userID: this.userDetails._id,
        firstName: this.customDetails.firstname,
        surName: this.customDetails.lastname,
        // avatar: "empty",
        email: this.customDetails.email,
        phone: this.customDetails.phone,
        licenseNumber: this.customDetails.licenseNumber,
        //profileSchemaObjId
        profile: this.customDetails?.profile,
      };
      this.generealDetailsSaveBtnLd = true

      if (this.selectedNewAvatarImage && typeof this.selectedNewAvatarImage == 'object') {
        let formData = new FormData();
        formData.append('image', this.selectedNewAvatarImage);

        uploadImage(formData)
          .then((response) => {
            // console.log('=>', response?.data?.url);
            updateData.avatar = response?.data?.url;
            beingAdminUpdateDriver(this.userDetails._id, updateData).then(
              async (userDetailRes) => {
                this.userDetailsEdit = false;
                this.selectedNewAvatarImage = null;
                alert("User Record Updated Successfully!");
                this.generealDetailsSaveBtnLd = false
              }
            );

          })
          .catch(err => {
            console.log('%cprofile.vue line:2723 err', 'color: #007acc;', err);
            alert("Error in updating User!");
          })

      } else {
        beingAdminUpdateDriver(this.userDetails._id, updateData)
          .then(
            async (userDetailRes) => {
              this.userDetailsEdit = false;
              this.generealDetailsSaveBtnLd = false
              alert("User Record Updated Successfully!");
            }
          )
          .catch(err => {
            console.log('%cprofile.vue line:2723 err', 'color: #007acc;', err);
            alert("Error in updating User!");
          })
      }


    },
    getShortAddress(address) {
      return address.length > 20 ? `${address?.slice(0, 20)}...` : address;
    },

    saveBusiness() {
      updateDriverProfile(this.$route.params.id, { business: this.business })
        .then((response) => {
          this.driverBusinessDialog = false;
          // this.userDetails.profile.driver.business = this.business
          this.businessDetailTable = [this.business]
        }).catch(err => {
          console.log('%cprofile.vue line:2723 err', 'color: #007acc;', err);
          alert("Error in updating business!");
        })

    },

    saveInsurance() {
      console.log('%cprofile.vue line:2968 object', 'color: #007acc;', typeof this.insurance.image);
      if (this.insurance.image && typeof this.insurance.image == 'object') {
        let formData = new FormData();
        formData.append('image', this.insurance.image);

        uploadImage(formData)
          .then((responseImage) => {
            console.log('=>', responseImage?.data?.url);
            // this.insurance.insuranceCoverage = (Array.isArray(this?.insurance?.insuranceCoverage) ? this?.insurance?.insuranceCoverage : this?.insurance?.insuranceCoverage?.split(','))
            this.insurance.insuranceCoverage = this.selectedInsuranceItems
            updateDriverProfile(this.$route.params.id, { insurance: { ...this.insurance, image: responseImage?.data?.url } })
              .then((response) => {
                this.driverInsuranceDialog = false;
                this.insuranceDetailTable = [{ ...this.insurance, image: responseImage?.data?.url }]
                // window.location.reload()

              }).catch(err => {
                console.log('%cprofile.vue line:2723 err', 'color: #007acc;', err);
                alert("Error in updating insurance!");
              })
          }).catch(err => {
            console.log('%cprofile.vue line:2723 err', 'color: #007acc;', err);
            alert("Error in updating license!");
          })

      } else {
        // this.insurance.insuranceCoverage = (Array.isArray(this?.insurance?.insuranceCoverage) ? this?.insurance?.insuranceCoverage : this?.insurance?.insuranceCoverage?.split(','))
        this.insurance.insuranceCoverage = this.selectedInsuranceItems
        updateDriverProfile(this.$route.params.id, { insurance: this.insurance })
          .then((response) => {
            this.driverInsuranceDialog = false;
            this.insuranceDetailTable = [this.insurance]
          }).catch(err => {
            console.log('%cprofile.vue line:2723 err', 'color: #007acc;', err);
            alert("Error in updating insurance!");
          })
      }

    },

    saveLicense() {
      if (this.license.image && typeof this.license.image == 'object') {
        let formData = new FormData();
        console.log('%cprofile.vue line:2974 this.license.image', 'color: #007acc;', this.license.image, typeof this.license.image);
        formData.append('image', this.license.image);
        uploadImage(formData)
          .then((responseImage) => {
            console.log('=>', responseImage?.data?.url);
            updateDriverProfile(this.$route.params.id, { license: { ...this.license, image: responseImage?.data?.url } })
              .then((response) => {
                this.driverLicenseDialog = false;
                this.licenceDetailTable = [{ ...this.license, image: responseImage?.data?.url }]
                // Update the image URL directly without reloading the page
                // this.licenceDetailTable[0].image = response?.data?.url;
                // window.location.reload()
              }).catch(err => {
                console.log('%cprofile.vue line:2723 err', 'color: #007acc;', err);
                alert("Error in updating license!");
              })
          }).catch(err => {
            console.log('%cprofile.vue line:2723 err', 'color: #007acc;', err);
            alert("Error in updating license!");
          })

      } else {
        updateDriverProfile(this.$route.params.id, { license: this.license })
          .then((response) => {
            this.driverLicenseDialog = false;
            this.licenceDetailTable = [this.license]

          }).catch(err => {
            console.log('%cprofile.vue line:2723 err', 'color: #007acc;', err);
            alert("Error in updating license!");
          })
      }
    }


  },
};
</script>
<style>
.line-container {
  padding-left: 6rem !important;
}

.line-container .item-tag {
  width: 200px;
}

.checkbox-align-top .v-input--selection-controls__input {
  margin-top: 3px !important;
}

.checkbox-row {
  display: flex;
}
</style>